













































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { MobileProfileProperties } from "./MobileProfileView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import CustomerTokenService from "@/services/CustomerTokenService";
import DateUtil from '@/utilities/DateUtil';

const SnackbarView = () => import("@/components/shared/SnackbarView.vue");

@Component({
    components: { SnackbarView },
    data: () => ({
        token: " ",
        expiresAt: null,
    }),
})
export default class MobileProfileGenerateTokenView extends Vue {
    @Prop() private properties: MobileProfileProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private snackbar = new SnackbarProperties();
    private customerTokenService = new CustomerTokenService();

    public get tokenHint() {
        if (this.$data.token === " ") {
            return null;
        }

        if (!this.$data.expiresAt) {
            return this.$t("hint.token-infinite");
        }
        return this.$t("hint.token-expires", [this.$data.expiresAt]);
    }

    public refreshFormat(r: any) {
        if (r.data.expiresAt) {
            const dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            const formatted = DateUtil.format(new Date(r.data.expiresAt), dateTimeFormat);
            this.$data.expiresAt = formatted;
        } else {
            this.$data.expiresAt = null;
        }
    }

    public async generateToken() {
        try {
            const r = await this.customerTokenService.generate({ current: true });
            this.refreshFormat(r);
            this.$data.token = r.data.token;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public copyToken() {
        var input = document.createElement("input");
        input.type = "text";
        input.style.top = "0";
        input.style.left = "0";
        input.style.zIndex = "-999";
        input.style.position = "fixed";
        input.value = this.$data.token;
        document.body.append(input);
        input.focus();
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);

        this.snackbar.timeout = 2000;
        this.snackbar.alignment = "bottom-center";
        this.snackbar.message = this.$t("message.clipboard-copied");
        this.snackbar.closable = false;
        this.snackbar.visible = true;
    }
}
